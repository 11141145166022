import React from "react"
import { StyledContentArea, StyledContentAreaContainer, StyledTitle } from "./styles"

import { StyledModuleContainer } from '@styles/global-components/_containers';

export default function ContentArea({
  module,
  isBlogPage
}) {
  module = module.contentArea ? module.contentArea : module.contentAreaBlog;

  if(module) return (
    <StyledModuleContainer
      $sectionOptions={module.sectionOptions}
      moduleSpacing={(module.spacingOptions && module.spacingOptions.enableSmallModuleSpacing) ? 'xs' : 'md'}
      id={module.sectionOptions && module.sectionOptions.sectionId}
      data-name="content-area"
  >
    <StyledContentAreaContainer 
      type={
          (module.spacingOptions && module.spacingOptions.enableInnerMargin) || isBlogPage ? 'inner' : (module.spacingOptions && module.spacingOptions.enableOuterMargin) ? 'outer' : ''
      }
    >
        {/* {module.title && <StyledTitle>{module.title}</StyledTitle>} */}

        {module.contentArea1 && (
          <StyledContentArea 
            display={module.display} 
            content={module.contentArea1} 
            fontOverrides={module.fontOptions && module.fontOptions.enableFontOverrides && module.fontOptions}
          />
        )}

        {module.contentArea2 && module.display !== 'one-column' ? (
          <StyledContentArea 
            display={module.display} 
            content={module.contentArea2} 
            fontOverrides={module.fontOptions && module.fontOptions.enableFontOverrides && module.fontOptions}
          />
        ): null}

        {module.contentArea3 && (
          <StyledContentArea 
            display={module.display} 
            content={module.contentArea3} 
            fontOverrides={module.fontOptions && module.fontOptions.enableFontOverrides && module.fontOptions}
          />
        )}

        {module.contentArea4 && (
          <StyledContentArea 
            display={module.display} 
            content={module.contentArea4} 
            fontOverrides={module.fontOptions && module.fontOptions.enableFontOverrides && module.fontOptions}
          />
        )}
      </StyledContentAreaContainer>
    </StyledModuleContainer>
  )
  return null;
}
