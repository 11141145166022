import styled, { css } from 'styled-components';
import { theme } from '@theme';
import WysiwygContent from '../../commons/wysiwyg-content';

import { StyledContainer } from '@styles/global-components/_containers';

export const StyledContentAreaContainer = styled(StyledContainer)`
    display: flex;
    flex-wrap: wrap;
`

// Should I delete this??
export const StyledTitle = styled.h2`
    width: 100%;
    margin-top: 0;
    font-size: 2.5rem;

    ${theme.mediaQuerys.smUp} {
        font-size: 3rem;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 3.5rem;
    }
`

export const StyledContentArea = styled(WysiwygContent)`
    width: 100%;

    ${props => props.display === 'two-column' && css`

        ${theme.mediaQuerys.smUp} {
            width: 50%;
            padding-right: 2.5%;

            &:last-of-type {
                /* width: 66%; */
                padding-right: 0;
                padding-left: 2.5%;
            }
        }

      
    `}

    ${props => props.display === 'one-third-two-third' && css`

        ${theme.mediaQuerys.smUp} {
            width: 33%;

            &:last-of-type {
                width: 66%;
                padding-left: 2rem;
            }
        }
    `}

    ${props => props.display === 'two-third-one-third' && css`

        ${theme.mediaQuerys.smUp} {
            width: 66%;
            padding-right: 2rem;

            &:last-of-type {
                width: 33%;
            }
        }
    `}

    ${props => props.display === 'three-column' && css`

        ${theme.mediaQuerys.smUp} {
            width: 33%;
            padding: 0 1rem;
        }
    `}

    ${props => props.display === 'four-column' && css`

        ${theme.mediaQuerys.smUp} {
            width: 50%;
            padding: 0 1rem;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 25%;
        }
    `}
`